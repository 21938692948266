import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LogoFullWhite, LogoSuomiareenaWhite, MenuIcon, CloseIconWhite } from './logo';
import { BodyTextL } from './text';
import '../style/custom.scss';
import { NavigationLink, ParentNavigationLink } from '../components/link';
import { RED } from '../utils/color-constants';
import { PAGES_INFO, MEDIA_INFO } from '../utils/pages-info';
import createTranslate from '../utils/translator-provider';
import styled from 'styled-components';

const mainLinkStyle =
  'px-1.5 lg:px-3 text-lg text-white font-extrabold leading-22px text-right';

const subParentLinkStyle = mainLinkStyle

const subLinksContainerStyle = `flex flex-col items-start pl-3 lg:px-6 pb-6 font-normal
  lg:absolute lg:hidden lg:group-hover:flex lg:group-hover:flex-col lg:shadow-lg`;

const subLinkStyle = 'mt-2 text-white text-lg font-core font-medium text-right';

const StyledNav = styled.nav`
  white-space: nowrap;
  background-color: var(--theme-color);
`
const ThemeColoredDiv = styled.div`
  width: 100%;
  white-space: normal;
  word-wrap: break-word;

@media (min-width: 1024px) {
  background-color: var(--theme-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 4em 0px;
  top: 4.03rem;
}

`

const SubMenu = ({children, ...rest}) => <ThemeColoredDiv className={subLinksContainerStyle} {...rest}>{children}</ThemeColoredDiv>


export default () => {
  const data = useStaticQuery(graphql`
    query {
      translations: allContentfulTranslation {
        nodes {
          key
          value {
            value
          }
        }
      }

      campaigns: allContentfulCampaign {
        nodes {
          id
          showInNavigationBar
          nameInNavigationBar
          slug
        }
      }
    }
  `);

  const translate = createTranslate(data.translations.nodes);

  const buttonShowStyle = 'w-10 lg:hidden';
  const buttonHideStyle = 'hidden';

  const getLinkContainerStyle = displayClassname => {
    return `w-full mt-4 lg:mt-0 lg:w-6/12 lg:flex flex-row justify-between items-center relative ${displayClassname}`;
  };

  const [linkContainerStyle, setLinkContainerStyle] = useState(
    getLinkContainerStyle('hidden')
  );
  const [menuButtonStyle, setMenuButtonStyle] = useState(buttonShowStyle);
  const [closeButtonStyle, setCloseButtonStyle] = useState(buttonHideStyle);

  const handleMenuButtonClick = () => {
    setMenuButtonStyle(buttonHideStyle);
    setCloseButtonStyle(buttonShowStyle);
    setLinkContainerStyle(getLinkContainerStyle('block'));
  };

  const handleCloseButtonClick = () => {
    setMenuButtonStyle(buttonShowStyle);
    setCloseButtonStyle(buttonHideStyle);
    setLinkContainerStyle(getLinkContainerStyle('hidden'));
  };

  return (
    <StyledNav className="w-full z-40 flex flex-row justify-center shadow-md relative lg:sticky top-0">
      <div className="w-11/12 lg:max-w-1200px block lg:flex flex-row justify-start">
        <div className="flex flex-row items-center justify-between my-2">
          <div className="flex flex-row items-center mr-8">
            <NavigationLink
              to={PAGES_INFO.landingPage.path}
              className="flex items-center">
              <div className="w-52px">
                <LogoSuomiareenaWhite className="py-1 lg:py-0 h-42px"/>
              </div>
            </NavigationLink>
          </div>
          <button
            onClick={() => handleMenuButtonClick()}
            className={menuButtonStyle}>
            <MenuIcon />
          </button>
          <button
            onClick={() => handleCloseButtonClick()}
            className={closeButtonStyle}>
            <CloseIconWhite />
          </button>
        </div>
        <div className={linkContainerStyle}>
          <LandingPageLinks translate={translate} />
          <SuomiAreenaPoriLinks translate={translate} />
          <SuomiAreenaApplyLinks translate={translate} />
          <BrieflyInEnglishLinks translate={translate} />
          <KortPaSvenskaLinks translate={translate} />
          <ContactsLinks translate={translate} />
        </div>
      </div>
    </StyledNav>
  );
};

const StyledLinkTab = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  /** This is quick hack to make mobile atleast somewhat usable */
  /** Make links justify to left, and easier to hit */
  @media (max-width: 1024px) {
    justify-content: initial;

    & a {
      height: 40px;
      width: 100%;
    }
  }
`

const LinkTab: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({children, ...rest})=>{
  return <StyledLinkTab {...rest}>{children}</StyledLinkTab>
}

const LandingPageLinks = ({ translate }) => {
  return <LinkTab style={{maxWidth: "274px"}} className="ml-auto lg:my-auto" >
      <NavigationLink
        to={PAGES_INFO.landingPage.path}
        className={mainLinkStyle}>
        {translate(PAGES_INFO.landingPage.translationKey)}
      </NavigationLink>
    </LinkTab>
}

const SuomiAreenaPoriLinks = ({ translate }) => {
  const maxWidth = "218px"
  return <LinkTab  style={{maxWidth: maxWidth, paddingTop:"21.5px", paddingBottom:"21.5px"}} className="ml-auto lg:ml-0 group flex flex-col lg:flex-none lg:flex-unset lg:hover:shadow-lg">
        <ParentNavigationLink
          to={PAGES_INFO.suomiAreenaPoriPage.path}
          className={subParentLinkStyle.replace("lg:px-3", "lg:px-8")}>
          {translate(PAGES_INFO.suomiAreenaPoriPage.translationKey)}
        </ParentNavigationLink>
        <SubMenu style={{maxWidth: maxWidth}}>
        <NavigationLink
              to={PAGES_INFO.suomiAreenaPoriInfoPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaPoriInfoPage.translationKey)}
          </NavigationLink>
          <NavigationLink
              to={PAGES_INFO.suomiAreenaPoriBecomePartnerPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaPoriBecomePartnerPage.translationKey)}
          </NavigationLink>
          <NavigationLink
              to={PAGES_INFO.suomiAreenaPoriOhjelmaPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaPoriOhjelmaPage.translationKey)}
          </NavigationLink>
          <NavigationLink
              to={PAGES_INFO.suomiAreenaPoriVastuullisuusPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaPoriVastuullisuusPage.translationKey)}
          </NavigationLink>
        </SubMenu>
    </LinkTab>
}

const SuomiAreenaApplyLinks = ({ translate }) => {
    const maxWidth = "218px"
    return <LinkTab  style={{maxWidth: maxWidth, paddingTop:"21.5px", paddingBottom:"21.5px"}} className="ml-auto lg:ml-0 group flex flex-col lg:flex-none lg:flex-unset">
          <NavigationLink
              to={PAGES_INFO.suomiAreenaPoriJoinToCrewPage.path}
              className={mainLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaPoriJoinToCrewPage.translationKey)}
          </NavigationLink>
    </LinkTab>
}

/* const SuomiAreenaGoesLinks = ({ translate }) => {
  return <LinkTab style={{maxWidth: "274px", paddingTop:"21.5px", paddingBottom:"21.5px"}} className="ml-auto lg:ml-0 group flex flex-col lg:flex-none lg:flex-unset lg:hover:shadow-lg">
          <ParentNavigationLink
            to={PAGES_INFO.suomiAreenaGoesPage.path}
            className={mainLinkStyle.replace("lg:px-3", "lg:px-8")}>
            {translate(PAGES_INFO.suomiAreenaGoesPage.translationKey)}
          </ParentNavigationLink>
          <SubMenu style={{maxWidth: "274px"}}>
          <NavigationLink
              to={PAGES_INFO.suomiAreenaGoesTietoaPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaGoesTietoaPage.translationKey)}
            </NavigationLink>
            <NavigationLink
              to={PAGES_INFO.suomiAreenaGoesOhjelmaPage.path}
              className={subLinkStyle}>
              {translate(PAGES_INFO.suomiAreenaGoesOhjelmaPage.translationKey)}
            </NavigationLink>
          </SubMenu>
        </LinkTab>
} */

const BrieflyInEnglishLinks = ({ translate }) => {
  return  <LinkTab className="group">
          <NavigationLink
            to={PAGES_INFO.brieflyInEnglishPage.path}
            className={mainLinkStyle}>
            {translate(PAGES_INFO.brieflyInEnglishPage.translationKey)}
          </NavigationLink>
        </LinkTab>
}

const KortPaSvenskaLinks = ({ translate }) => {
  return <LinkTab className="group">
        <NavigationLink
          to={PAGES_INFO.kortPaSvenskaPage.path}
          className={mainLinkStyle}>
          {translate(PAGES_INFO.kortPaSvenskaPage.translationKey)}
        </NavigationLink>
      </LinkTab>
}

const ContactsLinks = ({ translate }) => {
  return  <LinkTab style={{maxWidth: "274px"}} className="ml-auto lg:my-auto">
          <NavigationLink
            to={PAGES_INFO.contact.path}
            className={mainLinkStyle}>
            {translate(PAGES_INFO.contact.translationKey)}
          </NavigationLink>
        </LinkTab>
}