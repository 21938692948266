import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LogoFullWhite, OwlLogo, LogoSuomiareenaWhite } from './logo';
import { BodyTextS } from './text';
import { NavigationLink, ExternalLink } from '../components/link';
import { PAGES_INFO, TEMPLATES_INFO, MEDIA_INFO } from '../utils/pages-info';
import { WHITE } from '../utils/color-constants';
import createTranslate from '../utils/translator-provider';

const infoColStyle = 'mb-12 flex flex-col items-start md:flex-row items-center';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      translations: allContentfulTranslation {
        nodes {
          key
          value {
            value
          }
        }
      }

      campaigns: allContentfulCampaign {
        nodes {
          id
        }
      }
      contacts: contentfulItemGroup(title: {eq: "Contacts - footer"}) {
        header
        items {
          ... on ContentfulContact {
                email
              }
        }
        title
      }
      events: contentfulItemGroup(title: {eq: "Events - Footer"}) {
        header
        items {
          ... on ContentfulLiveEvent {
            date
            location
            city
            linkText
            linkUrl
          }
        }
        title
      }
    }
  `);

  const translate = createTranslate(data.translations.nodes);
  return (
    <footer className="w-full flex flex-col items-center bg-white">
      <RedSection translate={translate} contacts={data.contacts} /* events={data.events} */ />
      <DarkSection translate={translate} data={data} />
      <WhiteSection translate={translate} />
    </footer>
  );
};

const RedSection = ({translate, contacts /* events */}) => {
  const commonMargin = "";

  return(
    <div className="w-full bg-red px-4 flex flex-col">
      <div className="flex flex-col md:flex-row my-8 mx-auto w-full max-w-1200px">
        <div className="mb-8 md:mb-0 md:mr-4">
          <LogoSuomiareenaWhite style={{height: '53px'}} />
        </div>
        <div className="flex-auto flex flex-col md:flex-row items-start">
          <Box className="lg:text-right" title={translate('Postiosoite')}>
            {['Konepajankuja 7', '00510 Helsinki'].map((x, idx) =>
              <span key={idx} className="text-base font-normal leading-18px">{x}</span>
            )}
          </Box>

          <Box className="lg:text-center" title={translate(contacts.header)}>
            {contacts.items.map((contact, idx) =>
              <span key={idx} className="text-base font-normal leading-18px mb-4 md:mb-0">{contact.email}</span>
            )}
          </Box>

          {/* <Box className="lg:text-center" title={translate(events.header)}>
            {events.items.map((event, idx) =>
              <ExternalLink key={idx} className="text-base font-normal leading-18px mb-4 md:mb-0 text-white" to={event.linkUrl}>{translate(event.location)}</ExternalLink>
            )}
          </Box> */}
        </div>
      </div>
    </div>
  )
}

const darkSectionTextWithBordersClassNames = 'my-2 md:px-4 md:border-r border-white text-white text-base font-normal leading-18px'
const darkSectionTextWithoutBordersClassNames = 'my-2 md:px-4 text-white text-base font-normal leading-18px'

const DarkSection = ({data, translate}) => (
    <div className="w-full  bg-black-midnight flex flex-col items-center h-338px md:h-50px">
      <div className="text-left md:text-center flex flex-col md:flex-row items-center lg:auto-rows-min auto-rows-max m-auto">
        <div className="block m-auto h-42px md:h-30px md:mr-4 mb-4 md:mb-auto">
            <LogoFullWhite />
        </div>
        <ExternalLink to="https://www.mtvuutiset.fi/" className={darkSectionTextWithBordersClassNames}>{translate('footer.link.mtvNews')}</ExternalLink>
        <ExternalLink to="https://www.mtv.fi/" className={darkSectionTextWithBordersClassNames}>{translate('footer.link.mtvService')}</ExternalLink>
        <ExternalLink to="https://www.mtvuutiset.fi/makuja" className={darkSectionTextWithoutBordersClassNames}>{translate('footer.link.makuja')}</ExternalLink>
    </div>
  </div>
)


const whiteSectionTextWithBordersClassNames = 'my-2 md:pr-4 border-b leading-8 md:border-b-0 md:border-r border-black text-black text-base font-normal leading-18px'
const whiteSectionTextWithoutBordersClassNames = 'my-2 md:pr-4 border-b md:border-b-0 text-black text-base font-normal leading-18px'

// Open consent programmatically
const openCookieConsent = ()=>{
  const win = window as any;

  // Only open if consent is defined
  if(win && win.Optanon){
    (win as any).Optanon.ToggleInfoDisplay();
  }
}

const WhiteSection = ({translate}) => {
  return (
  <div className="w-full px-4 flex flex-col items-center">
    <div className="md:flex md:flex-rows grid grid-cols-2 gap-4	lg:auto-rows-min auto-rows-max text-center">
      <span className={`${whiteSectionTextWithBordersClassNames} cursor-pointer hover:underline`} onClick={_=>openCookieConsent()}>Evästeasetukset</span>
      <ExternalLink className={whiteSectionTextWithBordersClassNames} to="https://www.mtvuutiset.fi/yritys">Tietoa yhtiöstä</ExternalLink>
      <ExternalLink className={whiteSectionTextWithBordersClassNames} to="https://www.mtvuutiset.fi/yritys#tyopaikat">Avoimet työpaikat</ExternalLink>
      <ExternalLink className={whiteSectionTextWithBordersClassNames} to="https://www.mtvuutiset.fi/yritys/en/">About MTV</ExternalLink>
      <ExternalLink className={whiteSectionTextWithBordersClassNames} to="https://www.mtvspotti.fi/">Mainosta MTV:ssä</ExternalLink>
      <ExternalLink className={whiteSectionTextWithBordersClassNames} to="https://www.mtvuutiset.fi/yritys/tietosuoja/">Tietosuojalauseke</ExternalLink>
      <ExternalLink className={whiteSectionTextWithoutBordersClassNames} to="https://www.mtvuutiset.fi/yritys/kayttoehdot/">Käyttöehdot</ExternalLink>
    </div>
  </div>
  )
}

const Box = ({title, children, className}) => (
  <div className="flex-1 flex flex-col lg:flex-row justify-center my-4 md:my-0">
      <span className={"text-base text-left font-extrabold leading-none mb-4 md:mb-0"+ (className ? ` ${className}`: '')}>{title}</span>
      <div className="flex flex-col ml-0 lg:ml-6">
        {children}
      </div>
  </div>
  )
